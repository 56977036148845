import {displayNewBirthRegistrationForm} from '../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm'
import {displayNewMarriageRegistrationForm} from '../../../auto/js/forms/marriageRegistration/NewMarriageRegistrationForm'
import {displayNewDivorceRegistrationForm} from '../../../auto/js/forms/divorceRegistration/NewDivorceRegistrationForm'
import {displayNewDeathRegistrationForm} from '../../../auto/js/forms/deathRegistration/NewDeathRegistrationForm'
import {displayCivilRecordsList} from '../../../auto/js/lists/CivilRecordList'
import {displayNewOccupationForm} from '../../../auto/js/forms/metadata/OccupationForm'
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayNewCountryForm} from '../../../auto/js/forms/metadata/CountryForm'
import {displayCountriesList} from '../../../auto/js/lists/metadata/CountriesList'
import {displayOccupationList} from '../../../auto/js/lists/metadata/OccupationList'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import {displayDeathRecordList} from '../../../auto/js/lists/DeathRecordList'
import {displayUnionRecordList} from '../../../auto/js/lists/UnionRecordList'
import {whoami} from '../../../auto/js/users/UserInfo';
import {displayLocationsList} from "../../../auto/js/lists/metadata/LocationsList";
import {displayNewLocationForm} from "../../../auto/js/forms/metadata/LocationsForm";
import { displayDigitalizationApplicationList } from '../lists/digitalization/DigitalizationApplicationList'
import { displayBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/BirthRegistrationList'
import { displayAmendmentApplicationList } from '../../../auto/js/lists/amendmentApplication/AmendmentApplicationList'
import { displayMarriageRegistrationList } from '../../../auto/js/lists/marriageRegistration/MarriageRegistrationList'
import { displayDivorceRegistrationList } from '../../../auto/js/lists/divorceRegistration/DivorceRegistrationList'
import { displayDeathRegistrationList } from '../../../auto/js/lists/deathRegistration/DeathRegistrationList'
import { displayEIdApplicationList } from '../../../auto/js/lists/eIdApplication/EIdApplicationList'
import { displayIdCardList } from '../../../auto/js/lists/IssuedIdCardList'
import { displayEIdDocumentList } from '../lists/IssuedEIdDocumentList'

export const menu = () => {
    return {

        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "e-id": {
                submenu: {
                    "E-ID": {
                        options: {
                            search: {label: "Applications", do: () => displayEIdApplicationList()}
                        },
                        label: "E-ID"
                    },
                    other: {  
                        options: {
                            search: {label: "Issued Documents", do: () => displayEIdDocumentList()}
                        },
                    },
                },
                label: "E-ID"
                }
            }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    "occupations": {
                        options: {
                            list: {label: "Occupations", do: displayOccupationList},
                            new: {label: "New", do: displayNewOccupationForm},
                        },
                        label: "Occupations"
                    },
                    "countries": {
                        options: {
                            list: {label: "Countries", do: displayCountriesList},
                            new: {label: "New", do: displayNewCountryForm},
                        },
                        label: "Countries"
                    },
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        })
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "geography": {
                submenu: {
                    "locations": {
                        options: {
                            list: {label: "Geo Data", do: displayLocationsList},
                            new: {label: "New", do: displayNewLocationForm},
                        },
                        label: "Geo Data"
                    },
                },
                label: "Geography"
            }

        }) */
    }
}
