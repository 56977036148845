import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { getServiceUri } from "../../metadata";
import { showNotification } from "../../utils";

import { divorceRegistrationFields, approveReadyDivorceRegistrationForm,
	 rejectReadyDivorceRegistrationForm, loadDivorceRegistrationFormData } from "../../../../main/js/forms/divorceRegistration/DivorceRegistrationFormCommon";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";

import { createTableComponent } from "../../widgets/TableComponent";

let DivorceRegistrationForm = createFormComponent(divorceRegistrationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<DivorceRegistrationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={approve} id={this.props.id} buttons={() => getButtons(this.props.id)} />
		</>
		)
	}

}


export const displayReadReadyDivorceRegistrationForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} id={id}/>
	});
}

const approve = async (id) => {
	return approveReadyDivorceRegistrationForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case Approved`, "success");
	});
;
}

const reject = async (id) => {
    return rejectReadyDivorceRegistrationForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case rejected`, "success");
	});
;
}

const loadFormData = async (id) => {
	return await loadDivorceRegistrationFormData(id);
}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => approve(id)}> {t`Approve`} </button>
		</div>
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => reject(id)}> {t`Reject`} </button>
		</div>
	</div>
    </>
    )
}