export const ISSUED_DOCUMENTS_ORDER_BY_DOCUMENT_ID = "DOCUMENT_ID";
export const ISSUED_DOCUMENTS_ORDER_BY_DOCUMENT_TYPE = "DOCUMENT_TYPE";
export const ISSUED_DOCUMENTS_ORDER_BY_FACE = "FACE";
export const ISSUED_DOCUMENTS_ORDER_BY_ID = "ID";
export const ISSUED_DOCUMENTS_ORDER_BY_IS_VOTER = "IS_VOTER";
export const ISSUED_DOCUMENTS_ORDER_BY_NOTES = "NOTES";
export const ISSUED_DOCUMENTS_ORDER_BY_RESIDENCE_DEPARTMENT = "RESIDENCE_DEPARTMENT";
export const ISSUED_DOCUMENTS_ORDER_BY_RESIDENCE_MUNICIPALITY = "RESIDENCE_MUNICIPALITY";
export const ISSUED_DOCUMENTS_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const ISSUED_DOCUMENTS_ORDER_BY_VOTING_CENTER = "VOTING_CENTER";
export const ISSUED_DOCUMENTS_ORDER_BY_VOTING_DEPARTMENT = "VOTING_DEPARTMENT";
export const ISSUED_DOCUMENTS_ORDER_BY_VOTING_MUNICIPALITY = "VOTING_MUNICIPALITY";
export const ISSUED_DOCUMENTS_ORDER_BY_ISSUED_DATE = "ISSUED_DATE";
export const ISSUED_DOCUMENTS_ORDER_BY_EXPIRES = "EXPIRES";
export const ISSUED_DOCUMENTS_ORDER_BY_CANCELLED_DATE = "CANCELLED_DATE";
export const ISSUED_DOCUMENTS_ORDER_BY_CANCELLED_REASON = "CANCELLED_REASON";
