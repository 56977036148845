import React from "react";
import {v4 as uuidv4} from 'uuid';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { createFormComponent } from "../../../auto/js/widgets"
import { rest } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";

const issuedDocumentsFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "documentId", type:"text", x:1, y:2, layout:"col-md-12"},
    {name: "vitalRecordId", type:"number", x:1, y:3, layout:"col-md-12"},
    {name: "issuedDate", type:"date", x:1, y:4, layout:"col-md-6"},
    {name: "expires", type:"date", x:1, y:4, layout:"col-md-6"},
    {name: "cancelledDate", type:"date", x:1, y:5, layout:"col-md-6"},
    {name: "cancelledReason", type:"text", x:1, y:6, layout:"col-md-12"},
]

export const displayReadIssuedDocumentsForm = (id) => {
    let IssuedDocumentsFormComponent = createFormComponent(issuedDocumentsFields)

    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () =>  <IssuedDocumentsFormComponent key={uuid} loadData={async () => loadFormData(id)} buttons={() => null} readOnly={true} />
	});
}

const loadFormData = async (id) => {
    return await rest.read('issued-documents', id).then(response => {
		let form = response;
        if (form.issuedDate !== null)
            form.issuedDate = new Date().setHours(form.issuedDate[0], form.issuedDate[1]);
        if (form.expires)
            form.expires = new Date().setHours(form.expires[0], form.expires[1]);
        if (form.CancelledDate)
            form.CancelledDate = new Date().setHours(form.CancelledDate[0], form.CancelledDate[1]);
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
        let tagFilter = {and: true};
        tagFilter['issued-documents-tag'] = {issuedDocumentsId: id};
        return rest.search('issued-documents-tag', tagFilter).then(tags => {
            form['tags'] = tags
            if (tags.status)
                showNotification(response.message.split('Detail: ')[1], "error");
            return form;
        })
	})
}