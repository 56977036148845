export const E_ID_MTLB_ORDER_BY_ID = "ID";
export const E_ID_MTLB_ORDER_BY_FIRSTNAME = "FIRSTNAME";
export const E_ID_MTLB_ORDER_BY_LASTNAME = "LASTNAME";
export const E_ID_MTLB_ORDER_BY_PASSWORD = "PASSWORD";
export const E_ID_MTLB_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const E_ID_MTLB_ORDER_BY_CIVIL_STATUS_MTLB_ID = "CIVIL_STATUS_MTLB_ID";
export const E_ID_MTLB_ORDER_BY_BIRTHDATE = "BIRTHDATE";
export const E_ID_MTLB_ORDER_BY_GENDER = "GENDER";
export const E_ID_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const E_ID_MTLB_ORDER_BY_FACE = "FACE";
export const E_ID_MTLB_ORDER_BY_FACE_MIME_TYPE = "FACE_MIME_TYPE";
export const E_ID_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
