import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../../../auto/js/events/Gui';
import { birthRegistrationFields, loadBirthRegistrationFormData } from "../birthRegistration/BirthRegistrationFormCommon";
import { readDigitalizationAttachment } from "./DigitalizationSearchForm";

let DigitalizationApplicationForm = createFormComponent(birthRegistrationFields);
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		return (
		<>
			<DigitalizationApplicationForm key={this.props.key} loadData={async () => loadBirthRegistrationFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
		</>
		)
	}

}

	

export const displayReadApprovedDigitalizationApplicationForm = (id) => {
	readDigitalizationAttachment(id).then(response => {
        let fileContent = "data:".concat("image/png", ";base64,", response[0].content);
        let uuid = uuidv4();
        OPEN_VIEW_EVENT.publish({
            uuid, view: () => {
				return (
					<>
					<div className="adjudication row">
					<div className="col-md-7">
					<FormComponent uuid={uuid} id={id} />
					</div>
					<div className="col-md-5">
						<img src={fileContent} width={'100%'}/>
					</div>
					</div>
					</>
				)}
        });
    })
}