import * as React from 'react';
import Noty from "noty";
import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

export const showNotification = (text, type) => {
	new Noty({
		text,
		type: type,
		theme: 'relax',
		timeout: 3000,
	}).show();
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export let ddmap = ( k, v ) => ( { key: k, value: v } );

export let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
		return data[a].localeCompare( data[b] ) 
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}

export const useConstructor = (callBack = () => {}) => {
	const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
	if (hasBeenCalled) return;
	callBack();
	setHasBeenCalled(true);
}

export const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}