import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";
import { MTLB_TYPE_BIRTH_REGISTRATION, MTLB_TYPE_E_ID } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";
import { E_ID_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/EIdMtlbOrderBy";

export const eIdApplicationListColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "firstname", field: "firstname"},
    {title: "lastname", field: "lastname"},
    {title: "birthdate", field: "birthdate"}
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "e-id-mtlb/search-by-mtlb-type-and-status/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "e-id-mtlb/count/search-by-mtlb-type-and-status/", "POST", filter);
}

export const getOrderBy = () => {
    return E_ID_MTLB_ORDER_BY_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_E_ID]
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        if (row.face != null) {
			let mimeType = row['faceMimeType'];
			face = "data:".concat("image/jpg", ";base64,", row.face);		
		}
        row.image = (face != null)?face:"/public/avatar.png";
        let date = row.birthdate
        if ( date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    list.push("E60");
    list.push("E61");
    return list;
}