import React from "react";


import * as Yup from 'yup';

import '../pages/printable.css';
import { Section } from "../utils";
import { AddressComponent } from "../../../auto/js/widgets/AddressComponent";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

export const civilRecordFormfields = [

    { name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
    { name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
    { name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
    { name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'First name must be at least two characters long').required('First name is required')},
    { name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    { name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'Last name must be at least two characters long').required('Last name is required')},
    {name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-12"},
    { name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
    { name: "maritalStatus", type: "select", x: 2, y: 7, layout: "col-md-6", metadata: () => maritalStatus },
    {name: "birthPlace", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "birthPlace", type: "custom", x:1, y:9, layout:"col-md-12", component: (name, disabled) => <AddressComponent name={name} disabled={disabled} linear={true}/>},
    {name: "reportedFatherName", type: "text", x:1, y:10, layout:"col-md-12"},
    {name: "reportedMotherName", type: "text", x:1, y:11, layout:"col-md-12"},
	{name: "reportedPaternalGrandfatherName", type: "text", x:1, y:12, layout:"col-md-12"},
	{name: "reportedPaternalGrandmotherName", type: "text", x:1, y:13, layout:"col-md-12"},
	{name: "reportedMaternalGrandfatherName", type: "text", x:1, y:14, layout:"col-md-12"},
	{name: "reportedMaternalGrandmotherName", type: "text", x:1, y:15, layout:"col-md-12"},
    { name: "notes", type: "text", x: 1, y: 16, layout: "col-md-12" }
];

export const birthCertificateContent =

    {
        leftTitle1: "Republic of ",
        leftTitle2: "Civil Registration and Identity Management",
        rightTitle1: "Republic of ",
        rightTitle2: "Civil Registration and Identity Management",
        mainTitle: " Birth Registration Certificate",
        mainSubTitle: "",
        signedBy: ""
    };