import React from "react";
import {v4 as uuidv4} from 'uuid';
import * as Yup from 'yup';

import { createFormComponent } from '../../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../../../auto/js/events/Gui';
import { AlertDialog } from "../../../../auto/js/widgets";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from "../../../../auto/js/services/i18ndb";
import { getServiceUri } from "../../../../auto/js/metadata";
import { showNotification } from "../../../../auto/js/utils";

import { birthRegistrationFields, approveReadyBirthRegistrationForm,
	loadBirthRegistrationFormData, 
	rejectReadyBirthRegistrationForm,
	updatePendingBirthRegistrationForm} from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { FileUploader } from "../../../../auto/js/widgets/FileUploader";

import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { readDigitalizationAttachment } from "./DigitalizationSearchForm";
import { Section } from "../../utils";


let BirthRegistrationForm = createFormComponent(birthRegistrationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<BirthRegistrationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly id={this.props.id} buttons={() => getButtons(this.props.id)} />
            <FileUploader onDropCallback={(file) => uploadCivilStatusMtlbAttachment(file, this.props.id)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const uploadCivilStatusMtlbAttachment = (file, id) => {
	rest.upload('civil-status-mtlb-attachment', id, file).then((response) => FILE_UPLOADED_EVENT.publish({ id: response.id, fileName: file.name }))
}
const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb-attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const handleCivilStatusMtlbAttachmentClick = (id) => {
	window.location = getServiceUri() + 'civil-status-mtlb-attachment' + '/' + id;
};

export const displayReadReadyDigitalizationApplicationForm = (id) => {
    readDigitalizationAttachment(id).then(response => {
        let fileContent = "data:".concat("image/png", ";base64,", response[0].content);
        let uuid = uuidv4();
        OPEN_VIEW_EVENT.publish({
            uuid, view: () => {
				return (
					<>
					<div className="adjudication row">
					<div className="col-md-7">
					<FormComponent uuid={uuid} id={id} />
					</div>
					<div className="col-md-5">
						<img src={fileContent} width={'100%'}/>
					</div>
					</div>
					</>
				)}
        });
    })
}

const approve = async (id) => {
	return approveReadyBirthRegistrationForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case Approved`, "success");
	});
;
}

const reject = async (id) => {
	return rejectReadyBirthRegistrationForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case Rejected`, "success");
	});
}

const reassign = async (id) => {
	let filter = {};
	filter.id = id;
	try {
        return rest.request(getServiceUri() + 'apply/reassign-digitalization-mtlb', 'POST', filter).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Case Reassigned`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

const loadFormData = async (id) => {
	return await loadBirthRegistrationFormData(id);
}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-4">
        <button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => approve(id)}> {t`Approve`} </button>
		</div>
		<div className="col-md-4">
		<button style={{ minWidth: '5rem', margin: 'auto' }} type="submit" className={'reject-button'} onClick={() => reassign(id)}> {t`Re-assign`} </button>
        </div>
		<div className="col-md-4">
		<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => reject(id)}> {t`Reject`} </button>
		</div>
	</div>
	</>
    )
}