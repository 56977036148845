import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { ISSUED_DOCUMENTS_ORDER_BY_DOCUMENT_ID, ISSUED_DOCUMENTS_ORDER_BY_ID } from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import { DOCUMENT_TYPE_E_ID, DOCUMENT_TYPE_ID_CARD } from "../../../auto/js/metadata/DocumentType";

const fields = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "E_ID", field: "id"},
    {title: "vitalRecordId", field: "vitalRecordId"},
];

export const displayEIdDocumentList = () => {
    let EIdList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <EIdList key={uuid} loadData={async (query) => buildData(query)} />
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["issued-documents"] = {documentTypeList: [DOCUMENT_TYPE_E_ID]};
    filter["orderBy"] = ISSUED_DOCUMENTS_ORDER_BY_ID;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["issued-documents"].columns.forEach(element => {
            if(element.type=='text'){
                filter["issued-documents"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getIssuedDocumentData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getIssuedDocumentData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/search-by-document-type/", "POST", filter);
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/count/search-by-document-type/", "POST", filter);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let faceUrl = null;
        if (row.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + row.faceId;	
		}
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";       
        let date = row.birthdate
      newRows.push(row);
    }
    return newRows;
}
