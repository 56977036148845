import React from "react";
import _ from 'lodash';
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_CIVIL_STATUS_CHANGE } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import * as Yup from 'yup';
import { formState } from "../../../../auto/js/forms/FormState";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const amendmentApplicationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
	{name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
	{name: "firstname", type: "text", x:1, y:3, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(1, t`First name must be at least one character long`).max(40, t`First name must be less than fourty characters long`).required(t`First name is required`)},
	{name: "secondname", type: "text", x:2, y:3, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(1, t`Second name must be at least one character long`).max(40, t`Second name must be less than fourty characters long`)},
	{name: "thirdname", type: "text", x:1, y: 4, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(1, t`Third name must be at least one character long`).max(40, t`Third name must be less than fourty characters long`)},
	{name: "fourthname", type: "text", x:2, y: 4, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(1, t`Family name must be at least one character long`).max(40, t`Family name must be less than fourty characters long`).required(t`Family name is required`)},
	{name: "fifthname", type: "text", x:1, y: 5, layout:"col-md-12",
		"validation": Yup.string().nullable().default(undefined).min(1, t`Marriage name must be at least one character long`).max(40, t`Marriage name must be less than fourty characters long`)},
	{name: "gender", type: "select", x:1, y: 6, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
	{name: "maritalStatus", type: "select", x:2, y:6, layout:"col-md-6", metadata: () => maritalStatus},
	{name: "birthdate", type: "date", x:1, y: 7, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthCountry", type: "autocomplete", x:2, y:7, layout:"col-md-12", metadata: () => metadataLoader.get('country')},
	{name: "birthDayUnknown", type: "checkbox", x:1, y:8, layout:"col-md-12"},
    {name: "birthMonthUnknown", type: "checkbox", x:1, y:9, layout:"col-md-12"},
    {name: "birthYearUnknown", type: "checkbox", x:1, y:10, layout:"col-md-12"},
	{name: "unknownFather", type: "checkbox", x:1, y:11, layout:"col-md-12"},
	{name: "fatherId", type: "number", x:1, y:11, layout:"col-md-6"},
    {name: "motherId", type: "number", x:2, y:11, layout:"col-md-6"},
    {name: "reportedFatherName", type: "text", x:1, y:13, layout:"col-md-6"},
    {name: "reportedMotherName", type: "text", x:2, y:13, layout:"col-md-6"},	
	{name: "notes", type: "text", x:1, y:16, layout:"col-md-12"}
];

export const saveAmendmentApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	dto.mtlbType = MTLB_TYPE_CIVIL_STATUS_CHANGE;
	dto.vitalRecordId = formData.id;
	dto.id = null;
	if (formData.faceId && formData.faceId != null) {
		return rest.read('face', formData.faceId).then(faceObject => {
			dto.face = faceObject.image;
			dto.faceMimeType = faceObject.faceMimeType;
			if (formData.birthCountry)
				dto.birthCountry = formData.birthCountry.key;
			try {
				return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
			} catch (err) {
				alert(err);
			}
		})
	}
	else {
		if (!formData.image.isEmpty) {
			let base64Image = formData.image.url;
			let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
			let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
			dto.face = base64;
			dto.faceMimeType = faceMimeType;
		}
		if (formData.birthCountry)
			dto.birthCountry = formData.birthCountry.key;
		try {
			return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
		} catch (err) {
			alert(err);
		}
	}
}

export const loadAmendmentApplicationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
		}
		if (response.birthTime !== null)
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};


		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingAmendmentApplicationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Amendment Application`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const updateAmendmentApplicationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Amendment Application`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const deleteAmendmentApplicationForm = async (id) => {
	try {
		return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted Amendment Application application`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const updateRejectedAmendmentApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Amendment Application`, "success");
		});
	} catch (err) {
		alert(err);
	}
}

export const rejectAmendmentApplicationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const rejectReadyAmendmentApplicationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const approveReadyAmendmentApplicationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const buildNewAmendmentApplicationForm  = () => {
	const empty = buildEmptyObject(amendmentApplicationFields);
	empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	empty['birthCountry'] = {key: 71, value: ''}
	return empty;
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-attachment'] = {};
	filter['civil-status-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb-attachment', filter)
}

const form2dto = (formData, dto) => {
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.birthTime != null && formData.birthTime instanceof Date)
		dto.birthTime = formData.birthTime.getHours() < 10 ?'0'+formData.birthTime.getHours() + ":" + formData.birthTime.getMinutes():formData.birthTime.getHours() + ":" + formData.birthTime.getMinutes()
}