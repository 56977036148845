import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoMetadataLoader } from '../../../auto/js/metadata/GeoMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const governorateObservable = new Observable();
const resetGovernorateEvent = new Observable();
const countyObservable = new Observable();
const resetCountyEvent = new Observable();
const resetDayraEvent = new Observable();

let resetList =[resetGovernorateEvent,resetCountyEvent,resetDayraEvent,];
export function AddressComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideGovernorate, setHideGovernorate] = React.useState(false);
    const [hideCounty, setHideCounty] = React.useState(true);
    const [hideDayra, setHideDayra] = React.useState(true);

    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideGovernorate(false);
            else
                setHideGovernorate(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideGovernorate(true);
        }
        resetFieldsFromIndex(0);
    }
    let governorateValue;
    const handleGovernorateChange = (value) => {
        if (value.key) {
            governorateObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideCounty(false);
            else
                setHideCounty(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            governorateObservable.publish([])
            setHideCounty(true);
        }
        resetFieldsFromIndex(1);
    }
    let countyValue;
    const handleCountyChange = (value) => {
        if (value.key) {
            countyObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideDayra(false);
            else
                setHideDayra(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            countyObservable.publish([])
            setHideDayra(true);
        }
        resetFieldsFromIndex(2);
    }
    let dayraValue;
    const handleDayraChange = (value) => {
        if (value.key) {
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
    }
    
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoMetadataLoader.getArea(addressComponents[0]);
	        countryValue = { key: country.id, value: country.name };
	        countryObservable.publish(geoMetadataLoader.getGeoLevel(country.id, 1))
	    }
	    if (addressComponents[1]) {
	        let governorate = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        governorateValue = { key: governorate.id, value: governorate.name };
	        governorateObservable.publish(geoMetadataLoader.getGeoLevel(governorate.id, 2))
	    }
	    if (addressComponents[2]) {
	        let county = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2]);
	        countyValue = { key: county.id, value: county.name };
	        countyObservable.publish(geoMetadataLoader.getGeoLevel(county.id, 3))
	    }
	    if (addressComponents[3]) {
	        let dayra = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        dayraValue = { key: dayra.id, value: dayra.name };
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"country"} options={() => geoMetadataLoader.getRootNodes()} label={t`Country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideGovernorate || governorateValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"governorate"} options={() => ""} label={t`Governorate`} handleChange={(data) => handleGovernorateChange(data)} observable={countryObservable} reset={resetGovernorateEvent} defaultValue={governorateValue} disabled={props.disabled}/>}
                    	{(!hideCounty || countyValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"county"} options={() => ""} label={t`County`} handleChange={(data) => handleCountyChange(data)} observable={governorateObservable} reset={resetCountyEvent} defaultValue={countyValue} disabled={props.disabled}/>}
                    	{(!hideDayra || dayraValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"dayra"} options={() => ""} label={t`Dayra`} handleChange={(data) => handleDayraChange(data)} observable={countyObservable} reset={resetDayraEvent} defaultValue={dayraValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}