import React from "react";
import _ from 'lodash';
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_BIRTH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import * as Yup from 'yup';
import {formState} from "../../../../auto/js/forms/FormState";

const gender = {1:"MALE", 2:"FEMALE"};

export const eIdApplicationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "image", type:"image", x:1, y:2, layout:"col-md-12"},
    {name: "firstname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name: "lastname", type: "text", x:2, y:3, layout:"col-md-6"},
    {name: "birthdate", type: "date", x:1, y: 4, layout:"col-md-6"},
	{name: "gender", type: "select", x:1, y: 5, layout:"col-md-6", metadata: () => gender}
];


export const loadEIdApplicationFormData = async (id) => {
	return await rest.read('e-id-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat("image/jpg", ";base64,", response.face)			
		}
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['e-id-mtlb-tag'] = {eidMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('e-id-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingEIdApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated E ID`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateEIdApplicationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created E ID`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteEIdApplicationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted E ID application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedEIdApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['e-id-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'e-id-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated E ID`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectEIdApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'e-id-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyEIdApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'e-id-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyEIdApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'e-id-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const buildEIdApplicationFormEmptyObject  = () => {
    const empty = buildEmptyObject(eIdApplicationFields);
    empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	return empty;
}

const form2dto = (formData, dto) => {
	if(formData.birthdate != null && typeof(formData.birthdate) != 'string') {
		let birthdateAsDate = new Date(formData.birthdate);
		dto.birthdate = birthdateAsDate.getFullYear() + "-" + ('0' + (birthdateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + birthdateAsDate.getDate()).slice(-2);
	}
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.birthTime != null && typeof(formData.birthTime) != 'string') {
		const date = new Date(formData.birthTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.birthTime = birthHour + ":" + birthMinute;
	}

}

const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}